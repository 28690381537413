
import React from 'react'
import Layout from '../components/Layout'
import Footer from '../components/Footer'

const CookiesPage = () => (
  <>
    <Layout title={'Cookies'} transparent={false}>
      <div style={{ paddingTop: '120px' }}>
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/a2423c54-8948-42c6-a7a9-91507d67e4f4/cd.js" type="text/javascript" async=""></script>
      </div>
    </Layout>
    <Footer/>
  </>
)

export default CookiesPage
